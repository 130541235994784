import './src/css/style.css';

import initTracking from './src/tracking/initTracking';
import zohoSalesIQ from './src/tracking/zohoSalesIQ';
import zohoPageSense from './src/tracking/zohoPageSense';
import linkedIn from './src/tracking/linkedIn';

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`);
    console.log(`# IntersectionObserver is polyfilled!`);
  }
};

export const onRouteUpdate = () => {
  // Initialize tracking
  initTracking([
    {
      script: zohoSalesIQ,
      loadOn: 'functional',
    },
    {
      script: zohoPageSense,
      loadOn: 'statistics',
    },
    {
      script: linkedIn,
      loadOn: 'marketing',
    },
  ]);
};
